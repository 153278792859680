
<template>
        <div class="main-content" style="max-width: 1000px;">
              <Card class="card-custom card-block">
                  <template #title>
                    <div class="column-wrapper align-center">
                      <div class="column-three text-left"><back-button /></div>
                        <h4 class="c-black flex-1 column-three">{{$t('admin_data')}}</h4>
                        <div class="column-three"></div>
                    </div>
                  </template>
                  <template #content>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
                      <Card class="card-custom form-block">
                        <template #content>
                        <div class="space-y-1">
                        
                        <ValidationProvider
                    :name="$t('login')"
                    rules="required|min3"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">
                      {{ $t('login') }}<span class="c-red">*</span>:
                    </h4>
                    
                    <div class="wrapper-input">
                      <InputText 
                      autocomplete="off"
                      :maxlength="12"
                      v-model="admin.login" 
                      class="p-inputtext-sm fw"
                      :class="{ 'p-invalid' : errors.length > 0 }"
                        :placeholder="$t('login')" 
                        
                        />
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <ValidationProvider
                  :name="$t('password')"
                  rules="required|min8"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space title-input">
                    {{ $t('password') }}<span class="c-red">*</span>:
                  </h4>
                  <div class="wrapper-input">
                    <Password 
                    autocomplete="off"
                    v-model="admin.password"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    class="fw" 
                    :placeholder="$t('password')" 
                    toggleMask 
                    :feedback="false"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                      <ValidationProvider
                    :name="$t('role')"
                    rules="required"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">
                      {{ $t('role') }}<span class="c-red">*</span>:
                    </h4>
                    
                    <div class="wrapper-input d-flex">
                      <Dropdown
                      optionLabel="text"
                      optionValue="value"
                    v-model="admin.role_id"
                    :options="getFormattedRoles"
                    class="custom-dropdown"
                    style="flex: 1 1 auto"
                    :placeholder="$t('role')"
                    :filter="true"
                    :emptyFilterMessage="$t('no_data')"
                  >
                  </Dropdown>
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <span
                        > 
                          <h4 class="c-text white-space title-input">
                            {{ $t('active') }}:
                          </h4>
                          
                          <div class="wrapper-input d-flex">
                            <InputSwitch class="p-inputswitch-success" v-model="admin.is_active" />
                          </div>
                      </span>
              </div>
                        </template>
                      </Card>
                    <Button :disabled="loadingFlag" :loading="loadingFlag"
                    @click="handleSubmit(validate)" class="p-button-sm p-button-success fw mt-2"
                    icon="pi pi-save"    
                    :label="$t('save')"
                        >
                    </Button>
              </ValidationObserver>
                    </template>
                    </Card>
          </div>
</template>

<script>
import { mapGetters } from 'vuex';
import disableAutocomplete from '@/disable-autocomplete';
export default {
    name: 'add',
    computed: {
        ...mapGetters({
          roles: 'roles/getRole',
        }),
        getFormattedRoles(){
          return this.roles.map(role => {
            return {
              text: this.setRolesName(role.name),
              value: role.id
            }
          })
        },
    }, 
    watch: {
      
  },
  beforeRouterEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('setPageName', 'add_admin');
    })
  },
    data() {
        return {
        adminValid: true,
        loadingFlag: false,
        showPassword: false,
        admin:{
          login: '',
            // email: '',
            // name: '',
            // last_name: '',
            password: '',
            is_active: true,
            role_id: 1,
            },
        }
    },
    async mounted() {
      disableAutocomplete.disable();
      if (!this.roles.length) {
              await this.$store.dispatch('roles/awaitGetRoles');
            }
        this.$store.commit('setPageName', 'add_admin');
    },
    methods: {
      setRolesName(role){
            switch(role){
              case 'SUPERUSER':
              return 'Super user';
              case 'ADMIN':
              return 'Admin';
              case 'PROMO_MANAGER':
              return 'Promo manager';
              case 'FINANCIAL_MANAGER':
              return 'Financial manager';
              case 'GAME_MANAGER':
              return 'Game manager';
              case 'PRODUCT_MANAGER':
              return 'Product manager';
              case 'CFO':
              return 'CFO';
              case 'CRM_MANAGER':
              return 'CRM manager';
            }
          },
          handleSubmit(validate) {
            validate().then(valid => {
              if (valid) {
                      this.add();
              }
          });
          },
      async add(){
        this.loadingFlag = true;
        const res = await this.$store.dispatch('admins/awaitAddAdmin', this.admin)
        if (res) {
          await this.$store.dispatch('admins/awaitGetAdmins');
          this.$toast.add({ severity: 'success', summary: this.$t('admin_added'), life: 4000 });
          this.admin = {
            login: '',
              password: '',
              is_active: true,
              role: 1,
            };
            this.$router.push('/role-admins');
        } else{
          this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
        }
          this.loadingFlag = false;
      },
    },
}
</script>

<style lang="scss" scoped>
.v-card__title{
  text-transform: uppercase;
}
.form-block{
  //max-height: 214px;
  //overflow: auto;
  display: flex;
  flex-direction: column;
  span{
    display: flex;
    align-items: center;
  }
  .title-input {
    flex: 0 0 200px;
    display: flex;
    //justify-content: flex-end;
  }
  .wrapper-input {
    //flex: 0 0 200px;
   // width: 100%;
  flex: 1 1 auto;
  }
  .v-input{
      min-width: 170px;
      max-width: max-content;
      margin-right: 5px;
      //&:not(:last-child) {
      //}
      margin-bottom: 6px;
      &.error--text{
          margin-bottom: 0;
      }
  }
  &_height {
      flex-direction: column;
      align-items: stretch;
  }

  &__header {
  }

  &__inputs {
      display: flex;
      flex-wrap: wrap;
      .v-input{
          @media (minh:500px){
              max-width: 170px;
              
          }
      }
  }
}
.half {
  display: flex;
  @media (max-width:850px){
    flex: 1 1 100%;
  }    
  @media (min-width:850px){
    flex-direction: column;
      
  }
  .v-input{
    @media (max-width:850px){
      margin-right: 3px;
      max-width: 50%;
      min-width: 50%;
  }

  }
}
</style>